
import Page from '../views/Page.vue';
import { Component, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, AdminAuthMixin, AdminRoutingMixin } from '@/mixins';
import AdminCard from '../components/admin/AdminCard.vue';

@Component({
	components: {
		AdminCard,
		Page,
	}
})
export default class AdminDashboard extends Mixins(BAIconsMixin, AdminAuthMixin, AdminRoutingMixin) {
}

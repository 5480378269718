import { CrudApi } from './CrudApi';
import { BARatingFormula } from "@/models";
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { isEmpty, isNotEmpty } from '@/pipes';

class BARatingFormulaApi extends CrudApi<BARatingFormula> {
	constructor() {
		super('baRatingFormula', (obj) => new BARatingFormula().load(obj));
	}

	async exists(formula: BARatingFormula): Promise<BARatingFormula> {
		const query: RepositoryQuery<BARatingFormula> = { 
			$match: {...{ title: formula.title }, ...{ version: formula.version } }
		};
		const options: QueryOptions = {};
		const formulas: PaginatedResponse<BARatingFormula> = await this.queryAll(query, options);
		return ( formulas.count > 0 )? formulas.docs[0] : undefined;
	}

	async findActive(): Promise<BARatingFormula> {
		// query for active formula
		const query: RepositoryQuery<BARatingFormula> = { 
			$match: {...{ active: true } }
		};
		const options: QueryOptions = {};
		var formulas: PaginatedResponse<BARatingFormula> = await this.queryAll(query, options);

		// return the first formula listed as active
		// note: there should only be one active formula
		if( formulas.count > 0 ) return formulas.docs[0];

		// if no active formula found, set active
		let formula: BARatingFormula;
		formulas = await this.queryAll({}, options);

		// if no formulas exist, create a new formula
		if( formulas.count === 0 ) formula = new BARatingFormula;
		// use the latest formula as the active formula
		else formula = formulas.docs[formulas.docs.length - 1];	

		// set formula to active
		formula.active = true;
		await this.save(formula);
		return formula;
	}

	async setActive(formula: BARatingFormula): Promise<BARatingFormula> {
		if( isEmpty(formula) ) return undefined;

		// find the previously active formula
		const prevActive: BARatingFormula = await this.findActive();
		if( prevActive.title !== formula.title || prevActive.version !== formula.version ) {
			// un-activate the previous active formula
			prevActive.active = false;
			this.patch({id: prevActive.id, active: false});
		};

		// activate this formula
		formula.active = true;
		return this.patch({id: formula.id, active: true});
	}
}

export const baRatingFormulaApi = new BARatingFormulaApi();

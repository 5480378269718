
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { VuetifyMixin, PaginatedTableMixin, StringsMixin, BAIconsMixin } from '@/mixins';
import { AthleteReportModel, AthleteReportModelEx } from '@/models';
import { adminStore, notificationStore } from '@/store';
import { DataTableHeader } from 'vuetify';
import { AthleteProfileModel, TeamModel, CoachProfileModel } from '@/models';
import RecruitingProfileInfo from '../../components/profile/recruiting/RecruitingProfileInfo.vue';

class AthleteReportTableData {
	id: string;
	name: string;
	athleteId: string | null;
	team: string;
	score: number;
	date: Date;
	athleteReport: AthleteReportModel;
}

@Component({
	components: {
		Page,
		RecruitingProfileInfo,
	}
})
export default class AthleteReportsAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin){
	pageState: PageState = new PageState('Initial');

	mounted(): void{
		this.tableOptions.sortBy = ['date'];
		this.tableOptions.sortDesc = [true];
	}

	// cache
    athletes: Array<AthleteProfileModel> = [];
    teams: Array<TeamModel> = [];
	coaches: Array<CoachProfileModel> = [];
	async loadTable(): Promise<void> {
		try {
			this.isLoading = true;
			this.isLoaded = false;
			var query = this.TableQuery<AthleteReportModel>(this.search, ['athleteId', 'athlete.lastName', 'athlete.firstName', 'player.lastName','team.name','coach.lastName','coach.firstName']);
			await adminStore.loadAthleteReports({ query, options: this.TableQueryOptions });
			this.athleteReports = adminStore.athleteReports.map( report => new AthleteReportModelEx().load(report) );
			this.dataItemsCount = adminStore.athleteReportsCount;

			for( const report of this.athleteReports ) {
				await report.loadAthlete(this.athletes, this.teams);
				await report.loadAthleteCoach(this.coaches);

				// cache athletes
				if( report.HasAthleteProfile ) {
					const athlete = this.athletes.find(a => a.id === report.athlete.id );
					if( this.IsEmpty(athlete) ) this.athletes.push(report.athlete);
				}

				// cache team
				if( report.HasTeam ) {
					const team = this.teams.find(t => t.id === report.team.id )
					if( this.IsEmpty(team) ) this.teams.push(report.team);
				}

				// cache coach
				if( report.HasCoachProfile ) {
					const coach = this.coaches.find(c => c.id === report.coach.id );
					if( this.IsEmpty(coach) ) this.coaches.push(report.coach);
				}
			}
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading athlete report: ${e}`});
		} finally {
			this.isLoaded = true;
			this.isLoading = false;
			this.pageState = new PageState('Ready');
		}
	}

	get PageLoading(): boolean {
		return adminStore.athleteReportsLoading || !adminStore.athleteReportsInitialized || this.isLoading;
	}
	get MindsetReports(): AthleteReportModel[] {
		return adminStore.athleteReports;
	}

	get TableHeaders(): DataTableHeader<any>[] {
		return [
			{ text: 'Date', value: 'date', sortable: true },
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Athlete', value: 'athleteId', sortable: false },
			{ text: 'Team', value: 'teamId', sortable: false },
			{ text: 'Coach', value: 'coachId', sortable: false},
			{ text: 'Rating', value: 'rating', sortable: false, width:'20', align:'center' },
			{ text: '', value: 'actions', sortable: false },
			{ text: '', width:"25", value: 'data-table-expand' }
		]
	}

	athleteReports: Array<AthleteReportModelEx> = [];
	get AthleteReports(): AthleteReportModelEx[] {
		if( !adminStore.athleteReportsInitialized) return [];
		return this.athleteReports;
	}
	get AthleteReportsCount(): number {
		return adminStore.athleteReportsCount;
	}

	editingReport: boolean = false;
	editReport: AthleteReportModelEx;
	editLinkAthlete: AthleteProfileModel = undefined;
	editLinkAthleteUpdating: boolean = false;
	onLinkAthleteReport(report: AthleteReportModelEx): void {
		this.editReport = report;
		this.editingReport = true;
	}
	onUpdateEditAthlete(athlete: AthleteProfileModel) {
		this.editLinkAthleteUpdating = true;
		this.editLinkAthlete = athlete?? undefined;
		this.editLinkAthleteUpdating = false;
	}
	onCancelEdit(): void {
		this.editReport = undefined;
		this.editingReport = false;
	}
	onRemoveLink(): void {
		try {
			this.editReport.athleteId = undefined;
			this.updateAthleteReport(this.editReport);
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}
	onConfirmEdit(): void {
		if( !this.editLinkAthlete ) return;

		try {
			this.editReport.athleteId = this.editLinkAthlete.id;
			this.updateAthleteReport(this.editReport);
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}
	async updateAthleteReport(athleteReport: AthleteReportModel): Promise<AthleteReportModel> {
		try {
			const updatedReport = await adminStore.patchAthleteReport(athleteReport);
			if( this.editReport.athleteId ) {
				notificationStore.pushSnackbarSuccess({message: "Report " + athleteReport.id + " linked to " + this.editReport.PlayerName})
			} else {
				notificationStore.pushSnackbarWarning({message: "Report " + athleteReport.id + " UNLINKED FROM " + this.editReport.PlayerName})
			}
			this.editReport = undefined;
			this.editingReport = false;
			this.editLinkAthlete = undefined;
			this.updateSearch();
			return updatedReport;
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}

	onAthleteClick(athleteReport: AthleteReportTableData) {
		// TODO: Admin Athlete view
	}
	onViewAthleteReport(reportId: string): void {
		// not implemented - requires an ADMIN APP athlete review form or routing to coach app
	}

}

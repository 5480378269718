// resources.ts


export const RootLevelResources = {
	'athlete': true,
	'action': true,
	'athlete-report': true,
	'athleteAssessmentData': true,
	'baEvent': true,
	'bestAthletesEvent': true,
	'baEventAttendee': true,
	'baEventOrder': true,
	'baEventWaiver': true,
	'baPurchase': true,
	'baSchool': true,
	'calendar': true,
	'calendarEvent': true,
	'coach': true,
	'comment': true,
	'course': true,
	'customer': true,
	'depthChart': true,
	'discussion': true,
	'event': true,
	'eventLocation': true,
	'eventAssessment': true,
	'favouriteList': true,
	'featureFlag': true,
	'file': true,
	'fileRecord': true,
	'gameResult': true,
	'game-report': true,
	'leaderboard': true,
	'markupTagClipMux': true,
	'mindsetReport': true,
	'mindsetUser': true,
	'notification': true,
	'organization': true,
	'organizationPlayer': true,
	'paymentMethod': true,
	'product': true,
	'school': true,
	'scouting-observation': true,
	'scouting-report': true,
	'search': true,
	'selfAssessment': true,
	'sourceVideoMux': true,
	'sport': true,
	'statistic': true,
	'student': true,
	'subscription-plan': true,
	'subscription': true,
	'targetedSharing': true,
	'team': true,
	'uniqueToken': true,
	'userPreference': true,
	'user': true,
	'videoClipCategories': true,
	'videoClip': true,
	'video': true,
	'viewRecord': true,
};
export const RESOURCES = Object.keys(RootLevelResources);
export type HinderResource = keyof typeof RootLevelResources;
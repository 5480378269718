
import Page from '../Page.vue';
import { notificationStore } from '@/store/index';
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, PaginatedTableMixin, AthleteApiMixin, CoachRoutingMixin, BAIconsMixin, ColorMixin } from '@/mixins';
import { RepositoryQuery, QueryOptions, PaginatedResponse } from '@/../types';
import { MissingDataBehaviour, BARatingColor } from '@best-athletes/ba-types'
import { DataTableHeader } from 'vuetify';
import { AllRatingFormulaFactors, AthleteProfileModel, BARatingFormula, BARatingFormulaFactor } from '@/models';
import { athleteApi } from '@/api/AthleteApi';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { isEmpty, isNotEmpty } from '@/pipes';
import { baRatingFormulaApi } from '@/api/BARatingFormulaApi';
import { DateTime } from 'luxon';
import { wait } from '@/helpers/wait'

class BARating extends RecruitingProfileModel {
	rating: number;
	scores: Array<number> = [];
	lowestScore: number = 1;
	highestScore: number = 100;
	defaultFormula: BARatingFormula = new BARatingFormula();

	constructor(athleteProfile: AthleteProfileModel) {
		super(athleteProfile);
		this.compute(this.defaultFormula);
	}

	get Rating(): string {
		if( isEmpty(this.rating) ) return 'na';
		return this.rating.toFixed(0);
	}
	getData( obj: BARating, key: string ): number {
		if( isEmpty(obj) ) return undefined;

		const keys = key.split('.');
		if( (keys.length === 1) && isNotEmpty(obj[keys[0]])) {
			const data = obj[keys[0]];
			if( typeof(data) === 'number' ) return data;
			return Number(data);
		}

		return this.getData(obj[keys[0]], keys.slice(1).join('.'))
	}

	norm(value: number, valueMin: number, valueMax: number, normMin: number = 1, normMax: number = 100): number {
		const norm0to1 = (value-valueMin)/(valueMax-valueMin);
		return norm0to1 * (normMax - normMin) + normMin;
	}
	compute(formula: BARatingFormula): number {
		let maxScore: number = 0;

		// compute score for each factor
		// score(x) = x*a*a+x*b+c where x is between 0, 1
		this.scores = formula.factors.map(factor => {
			let data = this.getData(this, factor.key);

			// if factor is mis-constructed, ignore this factor
			if( factor.max <= factor.min ) return undefined;

			// handle missing data
			// default: athlete is assigned a default value
			// zero: athlete is penalized for not having this attribute with a 0 score
			// ignore: ignore this factor in computation
			if( isEmpty(data) ) {
				if( factor.missingBehaviour === MissingDataBehaviour.default ) data = factor.default;
				else if( factor.missingBehaviour === MissingDataBehaviour.zero ) data = 0;
				else if( factor.missingBehaviour === MissingDataBehaviour.ignore ) return undefined;
				else return undefined;
			}

			// constrain the data between min and max
			if( data < factor.min ) data = factor.min;
			if( data > factor.max ) data = factor.max;

			// normalize data between lowestScore and highestScore
			const norm = this.norm(data, factor.min, factor.max, this.lowestScore, this.highestScore);

			// compute the score for this factor
			const score = norm * factor.a * factor.a + norm * factor.b + factor.c;
			const maxFactorScore = this.highestScore * factor.a * factor.a + this.highestScore * factor.b + factor.c;
			maxScore = maxScore + maxFactorScore;
			return score;
		})

		// remove any unavailable scores
		const availableScores = this.scores.filter(s => s != undefined);
		if( availableScores.length === 0 ) return undefined;

		// compute the final rating as a percentage between 0 and maxScore
		return this.rating = this.norm((availableScores.reduce((acc, value) => acc + value, 0)), 0, maxScore, this.lowestScore, this.highestScore);
	}
}

@Component({
	components: {
		Page,
	}
})
export default class AthleteRatingsDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, AthleteApiMixin, CoachRoutingMixin, BAIconsMixin, ColorMixin){
	MissingDataBehaviour = MissingDataBehaviour;
	MissingDataBehaviours = Object.keys(MissingDataBehaviour);
	BARatingColors = Object.entries(BARatingColor).map(([key, value]) => ({key, value}));

	formula: BARatingFormula;
	backupFormula: BARatingFormula;
	existingFormula: BARatingFormula;

	mounted() {
		this.tableOptions.itemsPerPage = 2;
		this.tableOptions.sortBy = ['rating'];
		this.tableOptions.sortDesc = [true];
		this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 2]];

		this.loadActiveFormula();
	}

	async initializeBackup() {
		this.backupFormula = Object.assign({}, this.formula);
		this.existingFormula = undefined;
	}
	async loadActiveFormula() {
		this.formula = await baRatingFormulaApi.findActive();
		this.initializeBackup();
	}

	async loadTable(): Promise<void> {
		try {			
			this.isLoading = true;
			this.isLoaded = false;
			const query: RepositoryQuery<AthleteProfileModel> = this.TableQuery<AthleteProfileModel>(this.search, ['lastName', 'firstName', 'email', 'id', 'shortId', 'tags']);
			// query.$match = {...query.$match, ...{ deleted: this.showDeleted? true : {$any:[false, undefined, null]} }};
			const options: QueryOptions = this.TableQueryOptions;
			const response: PaginatedResponse<AthleteProfileModel> = await athleteApi.queryAll(query, options);
			const recruitingProfiles: Array<BARating> = await Promise.all(response.docs.map(async(athlete) => { 
				const recruitingProfile = await athleteApi.getAthleteRecruitingProfile(athlete.id, {as : 'admin'})
				const barating: BARating = new BARating(athlete).load(recruitingProfile);
				barating.compute(this.formula);
				return barating;
			} ));
			this.dataItems = recruitingProfiles;
			this.dataItemsCount = response.total;
			this.isLoaded = true;
			this.isLoading = false;
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading athletes: ${e}`});
		}
	}

	get TableLoading(): boolean {
		return this.isLoading;
	}
	get PageLoading(): boolean {
		return !this.IsAthleteApiReady || this.TableLoading;
	}
	get TotalItems(): number {
		return this.dataItemsCount;
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Name', value: 'lastName', sortable: false, },
			{ text: 'Rating', value: 'rating', sortable: false, align: 'center' },
			// { text: 'gen', value: 'gender', sortable: false, align: 'center' },
			// { text: 'DOB', value: 'birthDate', sortable: false, },
			{ text: 'Data', value: 'data', sortable: false, },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'actions', sortable: false });
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}
		return headers;
	}

	showFormulaControls: boolean = false;
	showLevelsControls: boolean = false;

	FactorData( obj: BARating, key: string ): string {
		if( this.IsEmpty(obj) ) return 'na';

		const keys = key.split('.');
		if( (keys.length === 1) && this.IsNotEmpty(obj[keys[0]])) {
			const data = obj[keys[0]];
			if( typeof(data) === 'string' ) return data;
			if( typeof(data) === 'boolean' ) return data? 'true':'false';
			if( typeof(data) === 'number' ) return (data < 10)? data.toFixed(1) : data.toFixed(0);
			if( data instanceof Date ) return this.formatDatePretty(data);
			if( data instanceof Array ) return data.toString();
			return typeof(data);
		}

		return this.FactorData(obj[keys[0]], keys.slice(1).join('.'))
	}

	RatingColor(rating: number, formula: BARatingFormula): string {
		for( const level of formula.levelsSorted ) {
			if( rating >= level.floor ) return level.color;
		}
		return 'primary';
	}
	RatingTextColor(rating: number, formula: BARatingFormula): string {
		return this.ComplementaryTextColor(this.RatingColor(rating, formula));
	}

	showConfirmSaveDlg: boolean = false;
	showConfirmDeleteDlg: boolean = false;
	async saveFormula(active: boolean = false) {
		try {
			// if overwriting an existing formula, update the ids
			if( this.IsNotEmpty(this.existingFormula) ) {
				this.formula['_id'] = this.existingFormula['_id'];
				this.formula['id'] = this.existingFormula['id'];
				this.formula.active = this.existingFormula.active;
			}
			// if the title or version has changed, save a new formula entry
			else if( this.backupFormula.title != this.formula.title || this.backupFormula.version != this.formula.version ) {
				delete this.formula['_id'];
				delete this.formula['id'];
				this.formula.active = active;
			}

			this.formula.created = new Date();
			this.formula = await baRatingFormulaApi.save(this.formula);
			notificationStore.pushSnackbarSuccess({message: `Formula ${this.formula.title} v${this.formula.version} saved`})
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error saving Formula: ${e}`})
		}
		await this.initializeBackup();
		await this.updateFilter();
	}
	async onFormulaTest() {
		await this.updateFilter();
	}
	async onFormulaSave() {
		this.existingFormula = await baRatingFormulaApi.exists(this.formula);
		if( this.IsNotEmpty(this.existingFormula) ) {
			this.showConfirmSaveDlg = true;
			return;
		}
		await this.saveFormula();
	}
	async onConfirmSaveCancel() {
		this.showConfirmSaveDlg = false;
	}
	async onConfirmSaveAccept() {
		await this.saveFormula();
		this.showConfirmSaveDlg = false;
	}
	async onConfirmSaveUpdateVersion() {
		this.formula.version = this.formula.version + 1;
		this.showConfirmSaveDlg = false;
		await this.onFormulaSave();
	}

	async loadFormulas() {
		this.formulasLoaded = false;
		this.formulasLoading = true;

		const response: PaginatedResponse<BARatingFormula> = await baRatingFormulaApi.queryAll({},{sort:{ fields: [{ field: 'created', desc: true }, { field: 'version', desc: true}] }});
		this.allFormulas = response.docs;

		this.formulasLoading = false;
		this.formulasLoaded = true;
	}

	formulaToDelete: BARatingFormula;
	async onFormulaDelete(formula: BARatingFormula) {
		if( this.IsEmpty(formula) ) return;
		this.formulaToDelete = formula;
		this.showConfirmDeleteDlg = true;
	}
	async onConfirmDeleteCancel() {
		this.showConfirmDeleteDlg = false;
	}
	async onConfirmDeleteAccept() {
		await baRatingFormulaApi.delete(this.formulaToDelete)
		this.showConfirmDeleteDlg = false;
		await this.loadFormulas();
	}

	showArchivedFormulas: boolean = false;
	async onFormulaArchive(formula: BARatingFormula) {
		if( this.IsEmpty(formula) || formula.archive ) return;
		formula.archive = true;
		formula = await baRatingFormulaApi.save(formula);
	}
	async onFormulaUnarchive(formula: BARatingFormula) {
		if( this.IsEmpty(formula) || !formula.archive ) return;
		formula.archive = false;
		formula = await baRatingFormulaApi.save(formula);
	}

	showAddFactorDlg: boolean = false;
	newFactor: BARatingFormulaFactor;
	isNewFactorChanging: boolean = false;
	async onAddFactorBegin() {
		this.showAddFactorDlg = true;
	}
	async onAddFactorAccept() {
		this.formula.factors.push(this.newFactor);
		this.showAddFactorDlg = false;
		this.newFactor = undefined;
		await this.onFormulaTest();
	}
	async onSelectNewFactor(item) {
		this.isNewFactorChanging = true;
		this.newFactor = new BARatingFormulaFactor().load(item);
		this.isNewFactorChanging = false;
	}
	get AvailableFactors(): Array<Partial<BARatingFormulaFactor>> {
		return AllRatingFormulaFactors.filter(f => !this.formula.factors.some(ff => ff.key === f.key));
	}

	showNewFormulaDlg: boolean = false;
	newFormulaTitle: string;
	isCreatingNewFormula: boolean = false;
	async onFormulaCreateBegin() {
		const now: Date = new Date();
		this.newFormulaTitle = `New Formula ${DateTime.fromJSDate(now).toFormat("MMM dd yyyy")}`;
		this.showNewFormulaDlg = true;
	}
	async onNewFormulaAccept() {
		this.isCreatingNewFormula = true;
		this.formula = new BARatingFormula();
		this.formula.title = this.newFormulaTitle;
		this.isCreatingNewFormula = false;
		this.showNewFormulaDlg = false;
		notificationStore.pushSnackbarSuccess({message: `Created ${this.formula.title}`});
		await this.onFormulaTest();
	}
	async onFormulaCopyBegin() {
		this.isCreatingNewFormula = true;
		delete this.formula['_id'];
		delete this.formula['id'];
		this.formula.active = false;
		this.formula.created = new Date();
		this.formula.title = `Copy of ${this.formula.title}`;
		this.formula.version = 1;
		notificationStore.pushSnackbarSuccess({message: `Created ${this.formula.title}`});
		this.isCreatingNewFormula = false;
	}

	showFormulaSelection: boolean = false;
	allFormulas: Array<BARatingFormula> = [];
	formulasLoaded: boolean = false;
	formulasLoading: boolean = false;
	get AllFormulas(): Array<BARatingFormula> {
		if( this.showArchivedFormulas ) return this.allFormulas;
		return this.allFormulas.filter(f => !f.archive);
	}
	async onFormulaLoad(formula: BARatingFormula) {
		this.formulasLoading = true;
		if( this.formula.title !== formula.title || this.formula.version !== formula.version ) {
			this.formula = formula;
		}
		this.formulasLoading = false;
		this.showFormulaSelection = false;
		await this.onFormulaTest();
	}
	async onFormulaSetActive(formula: BARatingFormula) {
		const foundFormula = this.allFormulas.find(f => (f.title === formula.title) && (f.version === formula.version));
		if( foundFormula ) foundFormula.active = true;
		else {
			formula = await baRatingFormulaApi.save(formula);
		}
		await baRatingFormulaApi.setActive(formula);
		await this.loadFormulas();
		await this.loadActiveFormula();
		await this.onFormulaTest();
	}
	async onFormulaSelectStart() {
		this.loadFormulas();
		this.showFormulaSelection = true;
	}
	get FormulaHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Title', value: 'title', sortable: false },
			{ text: 'Ver', value: 'version', sortable: false, align: 'center' },
			{ text: 'Created', value: 'created', sortable: false },
			{ text: 'Factors', value: 'factors', sortable: false, align: 'center' },
			{ text: 'Levels', value: 'levels', sortable: false },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'actions', sortable: false });
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}
		return headers;
	}

	isLevelColorChanging: boolean = false;
	async onSelectLevelColor() {
		this.isLevelColorChanging = true;
		await wait(250);
		this.isLevelColorChanging = false;
	}
}


import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';
import Alert from '../ui/Alert.vue';
import FormAddArea from '../forms/FormAddArea.vue';
import FileUploadArea from '../forms/FileUploadArea.vue';
import DatePicker from '../forms/DatePicker.vue';
import MonthPicker from '../forms/MonthPicker.vue';

@Component({
	components: { Alert, DatePicker, FormAddArea, FileUploadArea, MonthPicker },
})
export default class PhysicalAssessmentCSV extends Mixins(VuetifyMixin, BAIconsMixin){

	thisYear: number = new Date().getFullYear();
	@Prop() value = {
		eventData: [],
		valid: false,
	};
	input(){
		this.$emit('input', this.value);
	}

	$refs:{
		form: HTMLFormElement;
	}

	csvError = "";
	uploadCSVLoading: boolean = false;
	/**
	 * This uploader will break if you upload a CSV with CRLF line endings. (Looking at you, windows users)
	 */
	uploadCSV(csv: File){
		this.uploadCSVLoading = true;
		this.csvError = "";
		console.log(csv.type);
		// if(csv.type !== 'text/csv'){
		// 	console.error("File is not a valid CSV");
		// 	this.csvError = "File is not a valid CSV";
		// 	return;
		// }
		const fileReader = new FileReader()
		fileReader.onload = ($event: any) => {
			const csvString = $event.target.result;
			const csvRoster = csvString.split(/\r?\n/).map(line => line.split(','));
			console.log(csvRoster);
			csvRoster.slice(0).forEach(([
				assessmentDate,
				firstName,
				lastName,
				email,
				playingLevel,
				competitiveLevel,
				gender,
				dominantFoot,
				playingPosition,
				dateOfBirth,
				ageYear,
				ageMonthRemainder,
				ageMonthDecimal,
				age,
				mass,
				standingHeight,
				trueSittingHeight,
				sittingHeightWithBox,
				boxHeight,
				legLength,
				heightAgain,
				massAgain,
				sittingHeightAgain,
				legTrunk,
				ageLeg,
				ageSittingHeight,
				ageMass,
				massHeightRatio,
				bodyMassIndex,
				maturityOffset,
				ageOfPeakHeightVelocity,
				developmentalCategory,
				counterMovementJumpHeight,
				power,
				dropJumpContactTime,
				dropJumpHeight,
				reactiveStrengthIndex,
				tenMeterSprint,
				acceleration,
				twentyMeterSprint,
				thirtyFiveMeterSprint,
				twentyToThirtyFiveMeterSplit,
				speed,
				yoyoIntermittentRecoveryTestStage,
				yoyoIntermittentRecoveryTestDistance,
				maximalAerobicVelocity,
			]) => {
				console.log(email);
				let data = {
					assessmentDate,
					playingLevel,
					competitiveLevel,
					gender,
					dominantFoot,
					playingPosition,
					dateOfBirth,
					ageYear,
					ageMonthRemainder,
					ageMonthDecimal,
					age,
					mass,
					standingHeight,
					trueSittingHeight,
					sittingHeightWithBox,
					boxHeight,
					legLength,
					legTrunk,
					ageLeg,
					ageSittingHeight,
					ageMass,
					massHeightRatio,
					bodyMassIndex,
					maturityOffset,
					ageOfPeakHeightVelocity,
					developmentalCategory,
					counterMovementJumpHeight,
					power,
					dropJumpContactTime,
					dropJumpHeight,
					reactiveStrengthIndex,
					tenMeterSprint,
					acceleration,
					twentyMeterSprint,
					thirtyFiveMeterSprint,
					twentyToThirtyFiveMeterSplit,
					speed,
					yoyoIntermittentRecoveryTestStage,
					yoyoIntermittentRecoveryTestDistance,
					maximalAerobicVelocity,
				};
				let contact = {
					email,
					firstName,
					lastName
				};
				console.log(data);
				this.value.eventData.push({
					data: data,
					contact: contact
				});
				console.log(this.value)
			});
			this.uploadCSVLoading = false;
		}
		fileReader.readAsText(csv);
	}

	async submit($event: Event) {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

}

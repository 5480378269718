
import { Component, Mixins } from 'vue-property-decorator';
import AdminSchoolSearchModule from '@/store/admin/AdminSchoolSearch.store';
import { getModule } from 'vuex-module-decorators'
const adminSchoolSearchStore = getModule(AdminSchoolSearchModule);
import { PageState } from '@/models/PageState';
import { DataTableHeader } from 'vuetify';
import { VuetifyMixin, PaginatedTableMixin, BAIconsMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { QueryOptions, RepositoryQuery, ContextMenuItem } from '@/../types/interfaces';
import { SchoolModel } from '@/models/school/SchoolModel';
import SortIcon from '@/components/ui/SortIcon.vue';
import { schoolApi } from '@/api/SchoolApi';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { NotificationUIType } from '@/../types/enums';
import { UploadDialog, UploadResponse } from "@/components/utilities/upload/UploadDialog.vue";
import SchoolCoachesImport from '@/components/admin/schoolSearch/SchoolCoachesImport.vue';

@Component({
	components: {
		Page,
		SortIcon,
		UploadDialog,
		SchoolCoachesImport
	}
})
export default class SchoolDashboard extends Mixins(VuetifyMixin, PaginatedTableMixin, BAIconsMixin){
	pageState: PageState = new PageState('Initial');

	mounted() {
		this.tableOptions.sortBy = ['name'];
		this.tableOptions.itemsPerPage = 10;
	}

	showAdminArea: boolean = false;
	useMongoDB: boolean = true;
	
	schoolImportFile: string = '';
	showImportSchoolsDlg: boolean = false;
	importingSchools: boolean = false;
	defaultUploadNames = ['schools-final-v3(sports list).json.gz', 'niche_cleaned.json.gz', 'canadianschools.json.gz']

	showUploadCoachesDlg: boolean = false;
	importingCoaches: boolean = false;

	createSchool(): void{
		this.$router.push({
			name: AdminRoutes.SchoolCreate,
		});
	}
	editSchool(id: string): void{
		this.$router.push({
			name: AdminRoutes.SchoolEdit,
			params:{
				editSchoolId: id,
			}
		});
	}
	async uploadSchools(res: UploadResponse): Promise<void> {
		await schoolApi.importSchoolFile(res.key);
	}

	viewSchool(id: string): void{
		this.editSchool(id);
	}

	async deleteSchool(school: SchoolModel): Promise<void> {
		await schoolApi.delete(school).then(() => {
			notificationStore.pushSnackbarSuccess({message: "School '" + school.Name + "' deleted"});
		});
		this.updateSearch();
	}

	async createIndex(): Promise<void>{
		await schoolApi.createIndex();
		notificationStore.pushLocalNotification(new NotificationModel().load({
			uiType: NotificationUIType.Banner,
			message: 'School Index is being rebuilt'
		}))
	}

	async loadTable(): Promise<void>{
		this.pageState = new PageState('Loading');
		try{
			const query = this.TableQuery<SchoolModel>(this.search, ['Name', 'location.name', 'location.city', 'location.state', 'ipedsId', 'nicheUuid']);
			await adminSchoolSearchStore.loadSchoolList({ query, options: this.TableQueryOptions, useMongoDB: this.useMongoDB });
			this.dataItemsCount = adminSchoolSearchStore.totalSchools;
			// this.updateSchoolCSV();		// used for data extraction
        } catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading schools: ${e}`});
		} finally {
			this.pageState = new PageState('Ready');
		}
	}

    get PageLoading(): boolean {
        return this.tableUpdatePending || adminSchoolSearchStore.schoolsLoading;
    }
	get ActiveSchools(): number{
		return adminSchoolSearchStore.activeSchools;
	}
	get SchoolList(): SchoolModel[]{
		return adminSchoolSearchStore.schoolList;
	}

	TableHeaders: DataTableHeader<any>[] = [
		{ text: 'Name', value: 'name', sortable: true },
		{ text: 'Country', value: 'country', sortable: true },
		{ text: 'City', value: 'city', sortable: true },
		{ text: '', value: 'delete', sortable: false },
		{ text: '', value: 'view', sortable: false },
	];

	menuItems(item: SchoolModel): ContextMenuItem[]{
		return [
			{
				text: 'View',
				icon: "mdi-eye",
				click: () => {
					this.viewSchool(item.id);
				}
			},
			{
				text: 'Delete',
				icon: "mdi-delete",
				click: () => {
					this.deleteSchool(item);
				}
			},
		]
	}

	async importSchoolFromFile(): Promise<void> {
		this.importingSchools = true;
		await schoolApi.importSchoolFile(this.schoolImportFile)
		this.importingSchools = false;
	}

	csv: string = "";
	updateSchoolCSV() {
		this.csv = this.csv.concat(`iped, name, logo\n`);
		for( const school of this.SchoolList ) {
			if( !!school.schoolLogo && school.schoolLogo.length > 0 ) {
				this.csv = this.csv.concat(`${school.ipedsId}, ${school.Name}, ${school.schoolLogo}\n`);
			}
		}
	}
}


import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AdminCard extends Vue {
	@Prop({ type: String }) icon;
	@Prop({ type: String }) text;
	@Prop({ type: Boolean} ) disabled;
}


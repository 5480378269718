
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { VuetifyMixin, StringsMixin, BAIconsMixin, FormRulesMixin, StatusMixin } from '@/mixins';
import { SportModel } from "@/models/sport";
import { AthleteReportCategoryHeading } from "@/models/athleteReport/AthleteReportModel"
import { sportStore, notificationStore } from '@/store'
import { DataTableHeader } from 'vuetify';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { BodyPart, BodyPartValues, SportNameValues } from "@/../types/enums";
import { cloneDeep } from 'lodash';

@Component({
	components: {
		Page,
        FileUploadArea,
	}
})
export default class SportAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, FormRulesMixin, StatusMixin){
    loadingSports: boolean = true;
    sports: SportModel[];
    search = "";
    
    get SportNames(): string[] {
        return SportNameValues;
    }
    get BodyPartNames(): string[] {
        return BodyPartValues;
    }
    mounted() {
        this.loadSports();
    }
    async loadSports() {
        this.loadingSports = true;
        try {
            await sportStore.loadSports();
            this.sports = sportStore.sports;
        } catch(e) {
            notificationStore.snackbarShowError(`Error loading sports: ${e}`);
        }
        this.loadingSports = false;
    }
    get IsLoading(): boolean {
        return this.loadingSports || sportStore.loadingSports;
    }

    get Headers(): DataTableHeader<any>[] {
        return [
            {text: 'Icon', value: 'icon', sortable: false},
            {text: 'Name', value: 'name'},
            {text: 'Active', value: 'active', align: 'center', sortable: false},
            {text: 'Pro Search', value: 'proSearch', align: 'center', sortable: false},
            {text: 'Athlete Reports', value: 'athleteReports', align: 'center', sortable: false},
            {text: 'Physical Assessments', value: 'physical', align: 'center', sortable: false},
            {text: 'Scouting Reports', value: 'scouting', align: 'center', sortable: false},
            {text: '', width:"50", value: 'actions', sortable: false},
            {text: '', width:"25", value: 'data-table-expand'}
        ]
    }
    get AthleteReportTableHeaders(): DataTableHeader<any>[] {
        return [
            {text: 'Heading', value:'name', sortable: false, align: 'start', width:'100' },
            {text: 'Categories', value:'categories', align: 'start', sortable: false },
            {text: '', value:'actions', sortable: false, align: 'end', width:'25' },
        ]
    }

    async onClickActive(sport: SportModel) {
        sport.active = !sport.active;
        await sportStore.saveSport(sport);
    }
    async onClickProSearch(sport: SportModel) {
        sport.proSearch = !sport.proSearch;
        await sportStore.saveSport(sport);
    }


    setFocus(field: any) {
        field.focus();
    }
    newPosition: string = '';
    onAddPosition() {
        this.editSport.positions.push(this.newPosition);
        this.newPosition = '';
        this.setFocus(this.$refs.position);
    }
    onRemoveAllPositions() {
        this.editSport.positions = [];
        this.setFocus(this.$refs.position);
    }

    newAthleteReportHeading: string = '';
    onAddAthleteReportHeading() {
        const heading: AthleteReportCategoryHeading = new AthleteReportCategoryHeading;
        heading.name = this.newAthleteReportHeading;
        this.newAthleteReportHeading = '';
        this.setFocus(this.$refs.athletereportHeading);
        this.editSport.athleteReportHeadings.push(heading);
    }
    onRemoveAllAthleteReportHeadings() {
        this.editSport.athleteReportHeadings = [];
        this.setFocus(this.$refs.athletereportHeading);
    }

    newPhysicalLabel: string = '';
    onAddPhysicalLabel() {
        this.editSport.physicalLabels.push(this.newPhysicalLabel);
        this.newPhysicalLabel = '';
        this.setFocus(this.$refs.physicalLabel);
    }
    onRemoveAllPhysicalLabels() {
        this.editSport.physicalLabels = [];
        this.setFocus(this.$refs.physicalLabel);
    }
    newBodyPart: BodyPart = BodyPart.Unknown;
    onAddBodyPart() {
        this.editSport.bodyParts.push(this.newBodyPart);
        this.newBodyPart = BodyPart.Unknown;
        this.setFocus(this.$refs.bodyParts)
    }
    onRemoveAllBodyParts() {
        this.editSport.bodyParts = [];
        this.setFocus(this.$refs.bodyParts)
    }
    newScoutingLabel: string = '';
    onAddScoutingLabel() {
        this.editSport.scoutingLabels.push(this.newScoutingLabel);
        this.newScoutingLabel = '';
        this.setFocus(this.$refs.scoutingLabel);
    }
    onRemoveAllScoutingLabels() {
        this.editSport.scoutingLabels = [];
        this.setFocus(this.$refs.scoutingLabel);
    }
    newScoutingMoment: string = '';
    onAddScoutingMoment() {
        this.editSport.scoutingMoments.push(this.newScoutingMoment);
        this.newScoutingMoment = '';
        this.setFocus(this.$refs.scoutingMoment);
    }
    onRemoveAllScoutingMoments() {
        this.editSport.scoutingMoments = [];
        this.setFocus(this.$refs.scoutingMoment);
    }
    newScoutingEvaluation: string = '';
    onAddScoutingEvaluation() {
        this.editSport.scoutingEvaluations.push(this.newScoutingEvaluation);
        this.newScoutingEvaluation = '';
        this.setFocus(this.$refs.scoutingEvaluation);
    }
    onRemoveAllScoutingEvaluations() {
        this.editSport.scoutingEvaluations = [];
        this.setFocus(this.$refs.scoutingEvaluation);
    }

    editTitle: string = "Edit Sport";
    backupSport: SportModel;
    editSport: SportModel = new SportModel;
    validSport: boolean;
    editingSport: boolean = false;
    onAddSport() {
        this.editTitle = "Add Sport";
        this.editSport = new SportModel();
        this.editingSport = true;
    }
    onEditSport(sport: SportModel) {
        this.editTitle = `Edit ${sport.name}`;
        this.backupSport = cloneDeep(sport);
        this.editSport = sport;
        this.editingSport = true;
    }
    async onDeleteSport(sport: SportModel) {
        if( !confirm(`Delete ${sport.name}? This action cannot be undone`) ) return;

        await sportStore.deleteSport(sport);
        this.loadSports();
    }
    onCancelEditDlg() {
        if( this.IsNotEmpty(this.backupSport) ) this.editSport.load(this.backupSport);
        this.backupSport = undefined;
        this.editingSport = false;
    }
    async onSaveEditDlg() {
        if( !(this.$refs.form as HTMLFormElement).validate() ) {
            this.setError( "Please complete all of the required fields" );
            return;
        }
        
        await sportStore.saveSport(this.editSport);
        await this.loadSports();
        this.editingSport = false;
    }
}
